import Link from "next/link";

function Not_Found() {
  return (
    <main className="main-wrapper relative overflow-hidden">
      {/*...::: Section 404 Début :::... */}
      <section id="404-section">
        {/* Espacement de Section */}
        <div className="py-40 pt-36 xl:pb-[200px] xl:pt-[180px]">
          {/* Conteneur de Section */}
          <div className="global-container">
            {/* Contenu 404 */}
            <div className="mx-auto max-w-[954px] text-center">
              <h1 className="bg-[url(/assets/img_placeholder/th-1/404-text-image.jpg)] bg-contain bg-clip-text bg-center bg-no-repeat text-[150px] text-transparent sm:text-[220px] lg:text-[300px] xl:text-[350px]">
                404
              </h1>
              <h2 className="mb-[50px]">
                La page que vous cherchez est introuvable
              </h2>
              <Link
                href="/"
                className="button rounded-[50px] border-2 border-black bg-black py-4 text-white after:bg-colorOrangyRed hover:border-colorOrangyRed hover:text-white"
              >
                Retour à la page d'accueil
              </Link>
            </div>
            {/* Fin du contenu 404 */}
          </div>
          {/* Fin du Conteneur de Section */}
        </div>
        {/* Fin de l'Espacement de Section */}
      </section>
      {/*...::: Section 404 Fin :::... */}
    </main>
  );
}

export default Not_Found;
